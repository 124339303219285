<template>
  <b-modal
    id="gift-received-modal"
    modal-class="alert-window"
    :hide-footer="true"
    @hidden="clearCardGift"
    ><template slot="modal-header" slot-scope="{ close }">
      <h4 class="modal-headline">
        {{ $t("teams.chat.gift-received_popup.headline") }}
      </h4>
      <h5 v-if="gifter" class="modal-subheadline">
        {{
          $t("teams.chat.gift-received_popup.subheadline", { 0: gifter.name })
        }}
      </h5>
      <a href="#" class="close-button" @click="close()"> </a>
    </template>
    <template slot="default">
      <b-row no-gutters class="gifting-section"
        ><b-col class="gifter-col">
          <div class="gifter-avatar-field">
            <AvatarShield
              v-if="gifter.avatar_url"
              :avatar-url="gifter.avatar_url"
            /><span class="gifter-name bold">{{ gifter.name }}</span>
          </div></b-col
        ><b-col><div class="arrow-icon"></div></b-col
        ><b-col
          ><div
            v-if="card.image_urls"
            class="card-imgs"
            :style="{
              backgroundImage: `url(${require(`@/assets/img/cards/card-background_${card.probability.probability}.png`)})`,
            }"
          >
            <img
              draggable="false"
              :src="card.image_urls.image_url"
              class="card-img dead-center"
            /><img
              draggable="false"
              src="@/assets/img/common/sparkles.png"
              class="sparkles"
            /></div></b-col
      ></b-row>
    </template>
  </b-modal>
</template>

<script>
import AvatarShield from "@/components/base/avatar-shield.vue";
export default {
  name: "GiftReceivedModal",
  components: { AvatarShield },
  computed: {
    giftData() {
      return this.$store.state.chat.latestCardGift;
    },
    card() {
      if (!this.giftData) return null;
      return this.giftData.card;
    },
    gifter() {
      if (!this.giftData) return null;
      return this.giftData.user;
    },
  },
  mounted() {
    if (this.giftData) {
      this.$bvModal.show("gift-received-modal");
    }
  },
  methods: {
    clearCardGift() {
      this.$store.commit("chat/setLatestCardGift", null);
    },
  },
};
</script>
<style lang="scss">
#gift-received-modal {
  .modal-dialog {
    max-width: 600px;
  }

  .modal-headline {
    text-transform: uppercase;
    font-size: 18px;
  }
  .modal-subheadline {
    color: $light-blue;
    font-style: italic;
    font-size: 13px;
  }

  .gifting-section {
    height: 215px;
    margin: 48px;
    .col {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
    }
    .gifter-avatar-field {
      width: 100%;
      .avatar-shield {
        height: 150px;
        margin-bottom: 16px;
      }
      .gifter-name {
        color: $hightlight-yellow;
        font-size: 18px;
      }
    }
    .card-imgs {
      height: 100%;
      width: 143px;
      background-size: 100% 100%;
      .card-img {
        max-width: 100%;
        max-height: 100%;
      }
      .sparkles {
        width: 264px;
        height: 194px;
        position: absolute;
        left: -45px;
      }
    }
    .arrow-icon {
      width: 43px;
      height: 54px;
      background: url(~@/assets/img/chat/gift-card-arrow.png) center/100% 100%
        no-repeat;
    }
  }
}
</style>
